<template>
  <div class="first">
    <br>
    <ATitle text="第二届回顾" icon="6"></ATitle>
    <br>
    <div class="first-info center tc">
      <div class="prv">
        <div class="fl">
          <video poster="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/视频封面.jpg" controls="controls" src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会宣传片.mp4"></video>
        </div>
        <div class="flex info">
          <div class="item"><h1>7万方</h1><p>搭建面积</p></div>
          <div class="item"><h1>206家</h1><p>国内外参展企业</p></div>
          <div class="item"><h1>5.1万人次</h1><p>参观博览会</p></div>
          <div class="item"><h1>23个</h1><p>重点项目签约</p></div>
          <div class="item"><h1>374亿元</h1><p>总投资</p></div>
          <div class="item"><h1>110人次</h1><p>与会媒体记者</p></div>
        </div>
      </div>
    </div>
    <CTitle text="主旨论坛"></CTitle>
    <div class="f-swiper"><router-link tag="a" to="/first-forum?thread=7"><Swiper baseUrl="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/主旨论坛/论坛照片/" :list="mainImgList"></Swiper></router-link></div>
    <br>
    <CTitle text="第二届长三角综合交通发展大会"></CTitle>
    <div class="forum-outer">
      <div class="flex forum-wrap center">
        <router-link tag="a" to="/first-forum?thread=17" class="forum-item col4">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/开幕式暨主旨论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_520">
          </div>
          <div class="text">开幕式暨主旨论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=18" class="forum-item col4">
          <div class="flex img col4">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角综合交通协同发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_520">
          </div>
          <div class="text">长三角综合交通协同发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=19" class="forum-item col4">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角未来交通发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_520">
          </div>
          <div class="text">长三角未来交通发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=20" class="forum-item col4">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/长三角综合交通发展大会/长三角轨道交通发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_520">
          </div>
          <div class="text">长三角轨道交通发展论坛</div>
        </router-link>
      </div>
    </div>
    <CTitle text="2019中国车联网技术与应用发展大会"></CTitle>
    <div class="forum-outer">
      <div class="flex forum-wrap center">
        <router-link tag="a" to="/first-forum?thread=21" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/开幕式暨主旨论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">开幕式暨主旨论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=22" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/5G赋能论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">5G赋能交通发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=23" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/车联网大会/技术与应用论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">车联网技术与应用发展论坛</div>
        </router-link>
      </div>
    </div>
    <CTitle text="主题论坛"></CTitle>
    <div class="forum-outer">
      <div class="flex forum-wrap center">
        <router-link tag="a" to="/first-forum?thread=8" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/COTA全球未来交通发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">COTA全球未来交通发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=9" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/一带一路国际交通与物流发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px;">一带一路国际交通与物流<br>发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=10" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/智慧高速与自动驾驶论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">智慧高速与自动驾驶论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=11" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/第二届未来交通创新发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">第二届未来交通创新发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=12" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/未来城市智慧交通发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">未来城市智慧交通发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=13" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/浙江综合交通产业发展论坛暨招商推介会/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">浙江综合交通产业发展论坛暨招商推介会</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=14" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/综合交通产业创新大赛总决赛暨同期配套活动颁奖仪式/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text" style="line-height: 20px;padding-top: 10px">综合交通产业创新大赛总决赛暨同期<br>配套活动颁奖仪式</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=15" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/通用航空产业发展论坛/论坛封面.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">通用航空产业发展论坛</div>
        </router-link>
        <router-link tag="a" to="/first-forum?thread=16" class="forum-item">
          <div class="flex img">
            <img src="//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/论坛/AI%2B%E6%99%BA%E6%85%A7%E4%BA%A4%E9%80%9A%E4%BA%A7%E4%B8%9A%E5%8F%91%E5%B1%95%E8%AE%BA%E5%9D%9B/%E8%AE%BA%E5%9D%9B%E5%B0%81%E9%9D%A2.jpg?x-oss-process=image/resize,m_fill,h_340,w_620">
          </div>
          <div class="text">AI+智慧交通产业发展论坛</div>
        </router-link>
      </div>
    </div>
    <CTitle text="博览会照片"></CTitle>
    <div class="elevator-wrap center">
      <div class="fl elevator">
        <div v-for="(item, index) in imgList" class="flex item" :key="index" @click="cur=index" :class="cur==index&&'cur'">{{item.name}}</div>
      </div>
      <div class="sponsor-list">
        <SwiperNode :cur="cur" :imgList="imgList"></SwiperNode>
      </div>
    </div>
    <div class="annual-elevator">
      <router-link tag="div" class="annual" to="/fifth">第五届</router-link>
      <router-link tag="div" class="annual" to="/fourth">第四届</router-link>
      <router-link tag="div" class="annual" to="/third">第三届</router-link>
      <div class="annual cur">第二届</div>
      <router-link tag="div" class="annual" to="/first">第一届</router-link>
    </div>
  </div>
</template>
<script>
import Swiper from '@/components/Swiper'
import SwiperNode from '@/components/SwiperNode'
export default {
  components: {
    Swiper,
    SwiperNode
  },
  data() {
    return {
      cur: 0,
      mainImgList: ['开幕式大合影.jpg','启动仪式合影.jpg','项目签约.jpg','55818074.jpg','55816243.jpg','55819076.jpg','55819458.jpg','55820102.jpg','55823749.jpg','55831083.jpg','55839757.jpg','55844156.jpg'],
      imgList: [
        {
          name: '轨道交通馆',
          desc: '重点展示地铁、轻轨、单轨、有轨电车、磁悬浮、市域铁路、城际铁路、重载铁路、高速铁路等相关系统的新技术、新设备、新理念。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/轨道交通/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area1.png',
          img: [['961A1378.jpg','961A1381.jpg','961A1386.jpg','961A1387.jpg','961A1395.jpg','961A1396.jpg','961A1399.jpg','961A1407.jpg','961A1441.jpg'],['961A1444.jpg','961A1446.jpg','961A1448.jpg','961A1450.jpg','961A1454.jpg','961A1457.jpg','961A1461.jpg','961A1462.jpg','961A1464.jpg'],['961A1466.jpg','961A1468.jpg','961A1470.jpg','961A1472.jpg','961A1475.jpg','961A2022.jpg','961A2061.jpg','AF_10593.jpg','AF_10596.jpg']],
        },{
          name: '航空馆',
          desc: '重点展示航空制造产业、智慧机场智能化设备、临空经济及航空产业园展示等航空产业相关新产品和新业态。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/航空/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area4.png',
          img: [['1H3A0771.jpg','1H3A1413.jpg','961A1401.jpg','961A1406.jpg','961A1411.jpg','961A1413.jpg','961A1414.jpg','961A1417.jpg','961A1420.jpg'],['961A1421.jpg','961A1424.jpg','961A1427.jpg','961A1429.jpg','961A1431.jpg','961A1433.jpg','961A1435.jpg','961A1437.jpg','961A1438.jpg'],['961A1445.jpg','961A1481.jpg','961A1483.jpg','961A1485.jpg','961A1492.jpg','961A1494.jpg','961A2066.jpg','961A2101.jpg','961A2112.jpg']],
        },{
          name: '智慧交通馆',
          desc: '重点展示交通智能化建设最新技术成果、城市大交通建设成果和规划、公共交通及交通管理设备技术和解决方案、智能停车解决方案、智能网联汽车、新能源汽车等。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/智慧交通/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area8.png',
          img: [['1H3A0688.jpg','1H3A0691.jpg','1H3A0699.jpg','1H3A0700.jpg','1H3A0702.jpg','1H3A0706.jpg','1H3A0752.jpg','1H3A0754.jpg','1H3A0758.jpg'],['1H3A0762.jpg','1H3A0765.jpg','6H2A9742.jpg','6H2A9744.jpg','6H2A9745.jpg','6H2A9752.jpg','6H2A9759.jpg','6H2A9850.jpg','6H2A9859.jpg']]
        },{
          name: '快递及现代物流馆',
          desc: '重点展示物联网、物流信息化、智能仓储、自动化分拣、航空物流、港口物流、新能源物流及配套、无人车物流、物流金融、跨境电商物流等。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/快递物流/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area2.png',
          img: [['1H3A0704.jpg','1H3A0710.jpg','1H3A0715.jpg','1H3A0725.jpg','1H3A0740.jpg','1H3A0744.jpg','1H3A0747.jpg','6H2A9710.jpg','6H2A9800.jpg'],['6H2A9808.jpg','6H2A9822.jpg','6H2A9840.jpg','GY0A0558.jpg','YAO_2172.jpg','YAO_3406.jpg','YAO_3424.jpg','YAO_3425.jpg','YAO_3433.jpg']],
        },{
          name: '智能装备馆',
          desc: '重点展示交通产业智能制造设备、港航设备（船舶修造、海洋工程、钻探和生产平台、海洋开发设备、大型钢结构、集装箱等）、工程机械（挖掘机械、起重机械、气动工具等）。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/智能装备/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area3.png',
          img: [['1H3A0720.jpg','1H3A0726.jpg','246A2919.jpg','246A2966.jpg','6H2A9715.jpg','YAO_2094.jpg','YAO_2102.jpg','YAO_2131.jpg','YAO_3479.jpg'],['YAO_3493.jpg','YAO_3516.jpg','YAO_3521.jpg','YAO_3529.jpg','YAO_3534.jpg','YAO_3538.jpg','YAO_3550.jpg','YAO_3557.jpg','YAO_3584.jpg']],
        },{
          name: '5G应用馆',
          desc: '重点展示5G+交通行业应用场景，5G技术、5G产品、5G车联网、云VR/AR等5G融合应用。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/5G应用/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area5.png',
          img: [['246A2930.jpg','246A3037.jpg','246A3041.jpg','246A3044.jpg','246A3047.jpg','246A3178.jpg','246A3181.jpg','246A3192.jpg','246A3193.jpg'],['246A3197.jpg','AF_11419.jpg','GY0A0581.jpg','GY0A0585.jpg','GY0A0593.jpg','GY0A0672.jpg','GY0A0681.jpg','GY0A0801.jpg','GY0A0957.jpg']],
        },{
          name: '浙江综合交通产业馆',
          desc: '重点展示浙江各地市综合交通产业发展成就和规划，各地市综合交通产业园区、特色小镇、代表企业、重点招商项目、产业政策等。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/浙江综合交通产业/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area6.png',
          img: [['246A2872.jpg','246A2877.jpg','246A2886.jpg','246A2936.jpg','246A2945.jpg','246A2946.jpg','246A2948.jpg','246A2952.jpg','246A3019.jpg'],['246A3020.jpg','246A3029.jpg','246A3030.jpg','246A3057.jpg','246A3060.jpg','246A3062.jpg','246A3064.jpg','246A3067.jpg','246A3073.jpg']],
        },{
          name: '综交产业创新馆',
          desc: '重点展示交通领域独角兽、新型优秀企业、专精尖项目等。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/综交产业创新/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area7.png',
          img: [['246A2957.jpg','246A2961.jpg','246A2972.jpg','246A2978.jpg','246A2980.jpg','246A2989.jpg','246A2992.jpg','246A2994.jpg','246A2998.jpg'],['246A3001.jpg','246A3003.jpg','246A3014.jpg','246A3037.jpg','246A3078.jpg','246A3134.jpg','246A3143.jpg','6H2A9712.jpg','GY0A0883.jpg']],
        },{
          name: '互动体验园',
          desc: '主要包括车路协同体验、无人驾驶体验、无人机实时直播、风洞飞行等体验项目。',
          baseUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/首届回顾/2019/博览会照片/互动体验园/',
          iconUrl: '//itie-static.oss-cn-hangzhou.aliyuncs.com/PC端官网/展馆logo/exhibition-area9.png',
          img: [['1H3A1991.jpg','246A3338.jpg','246A3344.jpg','246A3373.jpg','246A3390.jpg','246A3422.jpg','246A3440.jpg','246A3472.jpg','246A3523.jpg'],['961A2321.jpg','ROCK4781.jpg','ROCK4800.jpg','TAO_1508.jpg','TAO_1541.jpg','TAO_1702.jpg','TAO_1761.jpg','TAO_1810.jpg','TAO_1859.jpg']],
        }
      ],
    }
  }
}
</script>

<style lang="less" scoped>
.first {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
  .first-info {
    .prv {
      margin: 20px 0;
      height: 240px;
      .fl {
        width: 423px;
        height: 238px;
        video {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          box-shadow: 0 0 20px rgba(28, 36, 52, 0.5);
        }
      }
      .info {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 50px;
        .item {
          text-align: center;
          height: 120px;
          width: 200px;
          padding: 20px 0;
          color: #125FAD;
          h1 {
            font-size: 28px;
          }
          p {
            color: #666;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .f-swiper {
    background: #F3F3F3;
    padding: 40px 0;
  }
  .forum-outer {
    background: url('../assets/maze_bg.svg') repeat;
    padding: 20px 0;
  }
  .forum-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
    .forum-item {
      display: block;
      cursor: pointer;
      width: 310px;
      height: 250px;
      border-radius: 4px;
      margin-bottom: 30px;
    }
    .img {
      width: 100%;
      height: 170px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      overflow: hidden;
      img {
        max-height: 170px;
        width: auto;
        transition: all ease-out 0.2s;
      }
    }
    .col4 {
      width: 260px;
    }
    .forum-item:hover {
      img {
        transform: scale(1.05);
      }
    }
    .text {
      height: 60px;
      color: #333;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border-top: 1px solid #F0F0F0;
      line-height: 60px;
      background: #F0F0F0;
    }
  }
  .elevator-wrap {
    .elevator {
      border-radius: 4px;
      width: 90px;
      left: 10px;
      border: 1px solid #D6D6D6;
      background: #FFF;
      div {
        margin: 0 auto;
      }
      .item {
        cursor: pointer;
        height: 80px;
        width: 90px;
        padding: 0 15px;
        border-bottom: 1px solid #D6D6D6;
        color: #666666;
        font-size: 16px;
        font-weight: 400px;
        text-align: center;
      }
      .item:last-child {
        border-bottom: none;
      }
      .cur {
        background: #2585E5;
        color: #FFF;
      }
    }
  }
  .sponsor-list {
    padding-left: 110px;
  }
}
.annual-elevator {
  position: fixed;
  right: calc(50% - 640px);
  top: 20%;
  border: 1px solid #D6D6D6;
  background: #FFF;
  .annual {
    cursor: pointer;
    height: 50px;
    width: 80px;
    border-bottom: 1px solid #D6D6D6;
    color: #666666;
    font-size: 16px;
    line-height: 50px;
    font-weight: 400px;
    text-align: center;
  }
  .annual:last-child {
    border-bottom: none;
  }
  .cur {
    background: #2585E5;
    color: #FFF;
  }
}
</style>
